import {WOO_SHOP_ADD_TO_CART_PHP_URL} from "./consts";
import $ from "jquery";
import {orderId, sessionPrefix} from "./index";
import Swal from "sweetalert2";

async function sendOredToCart() {
    // Show loader
    Swal.fire({
        title: "Μεταφέρεστε στο ηλεκτρονικό μας κατάστημα...",
        html: "H διαδικασία μπορεί να πάρει λίγα δευτερόλεπτα",
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
            Swal.showLoading();
        },
        customClass: {
            loader: 'custom-red-loader'
        }
    });

    // Save the order in the backend and get the order number and the thumb url.
    const url = "/core/place-order/",
        myInit = {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": $(
                    "input[name=csrfmiddlewaretoken]"
                ).val(),
            },
            mode: "cors",
            cache: "default",
            body: JSON.stringify({sessionPrefix, orderId}),
        };

    // Make POST request to save current selection.
    let results = await fetch(url, myInit);
    results = await results.json();
    if (results.success) {
        // Build the parameters to send to the add-to-cart.php script
        let orderNumber = results.orderNumber,
            woocommerceDataString = JSON.stringify(results.woocommerceData),
            encodedWoocommerceData = encodeURIComponent(woocommerceDataString),
            callBackUrl = encodeURIComponent(window.location.origin + "/configurator" + "/order"),
            params = `callback_url=${callBackUrl}&order_number=${orderNumber}&woocommerce_data=${encodedWoocommerceData}`;
        // Go to the shop page
        const URL = `${WOO_SHOP_ADD_TO_CART_PHP_URL}?${params}`;
        // console.log("params:", params);
        // console.log("URL:", URL);
        window.location.href = URL;
    }
}

export function initAddToCartButton() {

    const addToCardButton = document.querySelector("#add-to-cart");
    let addToCart = async function () {
        sendOredToCart();
    };
    addToCardButton.addEventListener("click", addToCart);
}
