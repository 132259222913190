import {BEDS, BASES_MENU_NAME} from "./consts";

export function getActiveBaseAndBedCodeOnFirstFetch({data}) {
    const code = {
        baseCode: null,
        bedCode: null
    };
    for (let i in data){
        if(data[i].a.name === BEDS){
            code.bedCode = data[i].b.code;
        }
        else if(data[i].a.name === BASES_MENU_NAME){
            code.baseCode = data[i].b.code;
        }
    }
    return code;
}
