import $ from "jquery";
import Swal from "sweetalert2";
import {activeParentCodes, sessionPrefix} from "./index";
import {BEDS} from "./consts";

export default function initBuilderTools() {
    let builderPanel = $('#builder-panel');
    $('#show-build-tools').on('click', function () {
        let isVisible = showHideElementAndToggleClass(builderPanel, 'visible');
        isVisible ? $(this).html($(this).attr('data-hide-menu-text')) : $(this).html($(this).attr('data-show-menu-text'));
    });

    $('#builder-panel .toggle').on('click', function () {
        let divName = $(this).closest('li').find('.div-name').attr('data-div-name');
        if ($(this).is(':checked')) {
            $('#' + divName).show();
        } else {
            $('#' + divName).hide();
        }
    });

    $('#update-current-db-layout').on('click', function () {
        $.post('/core/ucdl/', {
            'sessionPrefix': sessionPrefix,
            'csrfmiddlewaretoken': $("[name=csrfmiddlewaretoken]").val(),
        }, function () {
            fireSwalToast(2000, 'Saved Successfully', 'success', 'bottom-end', false);
        }).fail(function () {
            alert('Request failed!');
        });
    });
    $('#show-price-debug-info').on('click', function () {
        if ($(this).hasClass('show')) {
            $('#price-debug-info').hide();
            $(this).removeClass('show');
        } else {
            $('#price-debug-info').show();
            $(this).addClass('show');
        }
    });

}

function showHideElementAndToggleClass(element, className) {
    let isVisible = false;
    if (element.hasClass(className)) {
        element.hide();
        element.removeClass(className);
    } else {
        element.show();
        element.addClass(className);
        isVisible = true;
    }
    return isVisible;
}

export function fireSwalToast(timeMs, titleText, iconType, pos, progressBar) {
    const Toast = Swal.mixin({
        toast: true,
        position: pos,
        showConfirmButton: false,
        timer: timeMs,
        timerProgressBar: progressBar,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });
    Toast.fire({
        icon: iconType,
        title: titleText
    });
}

export function delayForListeners(callback, ms) {
    let timer = 0;
    return function () {
        let context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}

async function saveCurrentSelectionToSession(sendStatistic = false, statisticAction = null) {
    let sessionPrefix = JSON.parse(document.getElementById('sessionPrefix').textContent);
    const pillowsDimensions = document.querySelector("#pillow-option-1").checked;
    console.log('saveCurrentSelectionToSession', pillowsDimensions);
    const url = "/save_current_layout/",
        myInit = {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": $("input[name=csrfmiddlewaretoken]").val(),
            },
            mode: "cors",
            cache: "default",
            body: JSON.stringify({
                session_prefix: sessionPrefix,
                isBedsOn: activeParentCodes?.lastUsed() === BEDS,
                pillowsDimensions
            })
        };

    // Make POST request to save current selection.
    let results = await fetch(url, myInit);
    //console.log('results:', results);
    let response = await results.json();

    if (sendStatistic && statisticAction) {
        await sendStatisticToServer(statisticAction);
    }

    return response;
}

async function sendStatisticToServer(statisticAction) {
    // console.log('send');
    // Make POST request to update the statistics and excel file.
    const urlForExcel = `/save-to-excel/`,
        myInit = {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": $("input[name=csrfmiddlewaretoken]").val(),
            },
            mode: "cors",
            cache: "default",
            body: JSON.stringify({
                session_prefix: sessionPrefix,
                typeOfAction: statisticAction
            })
        };

    try {
        let newres = await fetch(urlForExcel, myInit),
            response = await newres.json(),
            {success} = response;
        if (success)
            console.log(
                success,
                "the data has been successfully saved in the excel"
            );
        else
            console.log(
                success,
                "the data has not been successfully saved in the excel"
            );
    } catch (e) {
        console.log(e);
    }
}

export {
    saveCurrentSelectionToSession,
    sendStatisticToServer
};