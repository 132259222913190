import {VirtualSelect} from "virtual-select-plugin/src/virtual-select";
import $ from "jquery";
import {allPropertyValues} from "./index";

require("tooltip-plugin/dist/tooltip.min");
require('popover-plugin/src/popover');

// initPageGetStarted();
export function createSelections(elementSelector, options) {
    VirtualSelect.init({
        ele: elementSelector,
        options: Object.keys(options).map(el => {
            return {label: el, value: el};
        }),
        multiple: true,
        autofocus: true,
        disableSelectAll: true,
        popupPosition: "right",
        setValueAsArray: true,
        maxValues: 2,
        optionsCount: 3,
        zIndex: 100,
        placeholder: 'Επιλέξτε κατηγορία',
        searchPlaceholderText: 'Αναζήτηση...',
        maxWidth: '430px'
    });
}

/* ***************************  set listener function for selections **************************** */
export function setListenerForSelecetions() {
    // const activeTextiles = new Set();
    document.querySelector('.vscomp-options ').addEventListener('click', findSelections);

    /* set Listener for delete button  */
    document.querySelector('.vscomp-clear-button').addEventListener('click', function () {
        document.querySelector('#category-c ').style.display = 'grid';
        Array.from($("#category-c .menu-item")).forEach(node => node.classList.remove("disabled"));
        document.querySelector('#category-d').style.display = 'grid';
        //Array.from($("#category-d .menu-item")).forEach(node => node.classList.remove("disabled"));
        Array.from($("#category-d .menu-item")).forEach(node => node.style.display = 'initial');
        document.querySelector('#breadcrumbs').style.display = 'block';
        document.querySelector('#bed-attributes-container > p')?.remove();
    });
}

export function findSelections() {
    let array1 = [];
    let values;

    let valueString = $('.vscomp-hidden-input').val();
    if (valueString === '') {
        values = [];
    } else {
        values = JSON.parse(valueString);
    }

    // temporary arrays
    let array2 = [];
    // array which matches the results
    $('#category-c .menu-item').removeClass('disabled');

    let results = [];
    values.forEach(property => {
        for (let prop in allPropertyValues) {
            if (property === prop) {
                if (array1.length === 0) {
                    array1.push(...allPropertyValues[prop]);
                } else {
                    array2.push(...allPropertyValues[prop]);
                }
            }
        }
    });

    document.querySelector('#category-c ').style.display = 'grid';
    Array.from($("#category-c .menu-item")).forEach(node => node.classList.remove("disabled"));
    document.querySelector('#category-d').style.display = 'grid';
    //Array.from($("#category-d .menu-item")).forEach(node => node.classList.remove("disabled"));
    Array.from($("#category-d .menu-item")).forEach(node => node.style.display = 'initial');
    document.querySelector('#breadcrumbs').style.display = 'block';
    document.querySelector('#bed-attributes-container > p')?.remove();

    if (array2.length === 0) {
        results = [...array1];
    } else {
        for (let choice of array1) {
            results.push(...array2.filter(el => el === choice));
        }
    }

    let flag_none_is_active = true;
    if (array1.length !== 0) {
        if (results.length === 0) {
            document.querySelector('#category-c ').style.display = 'none';
            document.querySelector('#category-d').style.display = 'none';
            document.querySelector('#breadcrumbs').style.display = 'none';
            let link = document.createElement("a");
            link.append('επαναφέρετε');
            if (!document.querySelector('#bed-attributes-container > p')) {
                let paragraph = document.createElement('p');
                paragraph.append('Δεν βρέθηκαν αποτελέσματα, δοκιμάστε να ', link);
                paragraph.append(' τα φίλτρα σας.');
                document.querySelector('#property-selections ').after(paragraph);
                document.querySelector('#bed-attributes-container p a').href = "#";
                document.querySelector('#bed-attributes-container a').addEventListener('click', function (e) {
                    $('.vscomp-clear-button').trigger('click');
                });
            }


        } else {
            Array.from($('#category-c .menu-item')).forEach(item => {
                if (!results.includes(item.dataset.name)) {
                    item.classList.add('disabled');
                } else {
                    flag_none_is_active = false;
                }
            });

            if (flag_none_is_active) {
                document.querySelector('#category-c ').style.display = 'none';
                document.querySelector('#category-d').style.display = 'none';
                document.querySelector('#breadcrumbs').style.display = 'none';
                let link = document.createElement("a");
                link.append('επαναφέρετε');
                if (!document.querySelector('#bed-attributes-container > p')) {
                    let paragraph = document.createElement('p');
                    paragraph.append('Δεν βρέθηκαν αποτελέσματα, δοκιμάστε να ', link);
                    paragraph.append(' τα φίλτρα σας.');
                    document.querySelector('#property-selections ').after(paragraph);
                    document.querySelector('#bed-attributes-container p a').href = "#";
                    document.querySelector('#bed-attributes-container a').addEventListener('click', function (e) {
                        $('.vscomp-clear-button').trigger('click');
                    });
                }
            }

        }
    }
    if (document.querySelector('#category-c .menu-item.active:not(.disabled)'))
        document.querySelector('#category-c .menu-item.active:not(.disabled)').click();
    else if (document.querySelector('#category-c .menu-item:not(.disabled)'))
        document.querySelector('#category-c .menu-item:not(.disabled)').click();
}

