import $ from "jquery";
import replaceImages from "./imagesTools";
import {getJson} from "./getJson";
import {MenuItem} from "./MenuItemsClass";
import {showBackgrounds} from "./backgroundsFunctions";
import {
    BASE_LENGTH_RANGE_SELECTOR,
    BASE_WIDTH_RANGE_SELECTOR,
    calcPrice,
    HEADBOARD_WIDTH_RANGE_SELECTOR,
    setListenersOnRebuild
} from "./UIfunctions";
import {saveCurrentSelectionToSession} from "./helpers";
import {BASE_WITHOUT_BEDFEET, BASES_MENU_NAME, BEDS, BEDS_WITHOUT_BEDFEET, HEADBOARD_MENU_NAME} from "./consts";
import {getActiveStateProps, setMinMaxValueAccordingToHeadboard, updateSlider} from "./dimensionsFunctions";
import {activeParentCodes} from "./index";

const sele = $('#selections-img');
const LETTERS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n'];

sele.on('click', openCloseSelections);

export function openCloseSelections() {
    const backgroundContainer = document.querySelector("#backgrounds-container");
    if (!backgroundContainer.classList.contains("hidden")) {
        showBackgrounds();
    }
    toggleSelections();

}

export function toggleSelections() {
    const selectionsContainer = $("#selections-container");
    if (selectionsContainer.hasClass("hidden")) {
        selectionsContainer.toggleClass("hidden");
    } else {
        setTimeout(function () {
            selectionsContainer.toggleClass("hidden");
        }, 800);
    }
    selectionsContainer.toggleClass("animate__fadeIn");
    selectionsContainer.toggleClass("animate__fadeOut");
    $('#selections-img img').toggleClass('red-filter');
}

const save = $('#layout-controls');

// on save of my selections it should call the back end and constructs the new selection
export default save.on('click', async function () {
    if (document.querySelector(".user-selection-text")) {
        document.querySelector(".user-selection-text").remove();
    }
    let {currentLayout} = await saveCurrentSelectionToSession(true, 'save_selection');

    let options = {...currentLayout};
    await constructNewSelection(options);
});

function findActiveBedorBaseName({menuState, isBedsOn}) {
    let bedID = $(`#category-a .menu-item[data-name='${BEDS}']`).attr("data-id");
    let BaseId = $(`#category-a .menu-item[data-name='${BASES_MENU_NAME}']`).attr("data-id");
    let activeBedID = menuState[`${bedID}`].b;
    let activeBaseID = menuState[`${BaseId}`].b;
    if (isBedsOn) {
        return $(`#category-b .menu-item[data-id='${activeBedID}']`).attr("data-name");
    }
    return $(`#category-b .menu-item[data-id='${activeBaseID}']`).attr("data-name");
}

let index = 0;

//function that constructs the new selection in UI
//it needs the thumb the id the sessionlayout and menustate
async function constructNewSelection(options) {
    try {
        let {id, layoutState, menuState, thumb, isBedsOn, dimensions, activeMenuItemBName, pillowsDimensions} = options;
        // console.log("options", options);
        global.layoutState = layoutState;
        let selectionsContainer = document.querySelector("#user-selections");

        let newString = `
        <div class="layout-selection obj" id='obj_${index}' data-id='${id}' data-name="${activeMenuItemBName}" data-layout='${JSON.stringify(layoutState)}'
        data-menu='${JSON.stringify(menuState)}' data-pillows-dimensions='${pillowsDimensions}' data-isbed='${JSON.stringify(isBedsOn)}' data-dimensions='${JSON.stringify(dimensions)}' 
        >
            <div class="img-container">
            <div class="remove-selection">
            <button type="button" class="btn-close" aria-label="Close"></button>
</div>
                <img src='data:image/png;base64,${thumb}' />                
            </div>
        </div>
        `;
        index++;
        let newNode = new DOMParser().parseFromString(newString, 'text/html').body.childNodes[0];
        selectionsContainer.append(newNode);

        document.querySelector(`[data-id="${id}"] button`).addEventListener('click', async function (e) {
            e.stopPropagation();
            document.querySelector(`[data-id="${id}"]`).remove();
            let sessionSavedUserSelections = $("#userSelections")[0].textContent;
            let arrayOfObjects = JSON.parse(sessionSavedUserSelections);
            const newSavedUserSelections = arrayOfObjects.filter((options) => options.id !== id);
            document.querySelector("#userSelections").textContent = JSON.stringify(newSavedUserSelections);

            const body = JSON.stringify(
                {
                    session_prefix: JSON.parse(document.getElementById('sessionPrefix').textContent),
                    id
                }
            );
            const url = "/remove_saved_layout/";
            const myInit = {
                method: 'POST',
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    "X-CSRFToken": $('input[name=csrfmiddlewaretoken]').val()
                },
                mode: 'cors',
                cache: 'default',
                body
            };
            let results = await fetch(url, myInit);
            let response = await results.json();

            if (response.length === 0) {
                voidSessionSelections();
            }
        });

        document.querySelector(`#user-selections [data-id="${id}"]`).addEventListener('click', async function (e) {
            //we find the active child of menu items and remove class active
            if (pillowsDimensions) {
                document.querySelector("#pillow-option-1").checked = true;
            } else {
                document.querySelector("#pillow-option-2").checked = true;
            }
            let curLayout = layoutState;
            let curMenuState = menuState;

            let getJsonId = "";
            if (isBedsOn) {
                let beds = document.querySelector(`#category-a [data-name="${BEDS}"]`);
                activeParentCodes.setLastUsed(beds);
                getJsonId = beds?.dataset.id;

            } else {
                let headBoard = document.querySelector(`#category-a [data-name="${HEADBOARD_MENU_NAME}"]`);
                activeParentCodes.setLastUsed(headBoard);
                getJsonId = headBoard?.dataset.id;
            }
            if ($("#bed-foot").hasClass("active")) {
                $("#bed-foot").removeClass("active");
                $("#bed-feet").addClass("hidden");
                $("#category-b").removeClass("hidden");
                $("#bed-attribute").removeClass("hidden");
                $("#show-hide").removeClass("hidden");
                $("#category-c").removeClass("hidden");
                $("#category-d").removeClass("hidden");
                $("#dimensions").removeClass("disabled");
            }
            document.querySelector(`#category-a .menu-item.active`)?.classList.remove("active");
            document.querySelector('#category-b').classList.remove('leyka-active');
            document.querySelector('#category-c').classList.remove('leyka-active');
            document.querySelector("#pillow-dimantions").style.display = 'none';
            document.querySelector('#leyka-dimantions').style.display = 'none';
            document.querySelector('#bed-attribute').style = 'display: initial;';
            document.querySelector("#property-selections").classList.remove("hidden");
            async function changeImages() {
                await closureForImageChange(curLayout);
                let newJson = await getJson(e, 'a', +getJsonId, false, '', '', curMenuState, getJsonId);
                for (let letter of LETTERS.slice(LETTERS.indexOf('a') + 1)) {
                    $(`#category-${letter}`).empty();
                }

                newJson.menuData.forEach(el => {
                    const text = el.subCategoryItems.filter(subEl => subEl.active === true)[0].showInFrontend["bed-text"];
                    document.querySelector('#bed-text').textContent = text;

                    const {overwriteCategory} = el.subCategoryItems.filter(
                        subEl => subEl.active === true
                    )[0].showInFrontend;
                    document.querySelector("#tivoli-subcategories").innerHTML = "";
                    let menuItem = new MenuItem(
                        el.id,
                        el.active,
                        el.name,
                        el.category,
                        el.structure,
                        el.thumbUrl,
                        el.images,
                        el.subCategoryItems,
                        el.showInFrontend,
                        el.dontShowInFrontend,
                        el.category
                    );
                    menuItem.buildMenuItem();

                    if (overwriteCategory) {
                        $("#category-b [data-name='Tivoli']").addClass("sub-category-active");
                        $(".add-pillows").removeClass("hidden");
                        $("#add-pillows").removeClass("hidden");
                        $("#add-pillows input").prop("checked", true);
                        $("#tivoli-subcategories").removeClass("hidden");
                    } else {
                        $(".add-pillows").addClass("hidden");
                        $("#add-pillows").addClass("hidden");
                        $("#tivoli-subcategories").addClass("hidden");
                    }
                });
                let podarakiaId = $(`#category-a .menu-item[data-name="Ποδαράκι"]`).attr("data-id");
                $("#bed-feet .menu-item").removeClass("active");
                let bactive = curMenuState[`${podarakiaId}`].b;

                $(`#bed-feet .menu-item[data-id="${bactive}"]`).addClass("active");

                setListenersOnRebuild();
                document.querySelectorAll(`[data-id="${getJsonId}"]`)[1].remove();


                $("#category-a .menu-item").removeClass("active");
                if (isBedsOn) {
                    $(`#category-a .menu-item[data-name="${BEDS}"]`).addClass("active");
                    $(".dimensions ").addClass("hidden");
                    $("#bed-text-container").removeClass("hidden");
                } else {
                    $(`#category-a .menu-item[data-name="${HEADBOARD_MENU_NAME}"]`).addClass("active");
                    $(".dimensions.headboards").removeClass("hidden");
                    $(".dimensions.bases").addClass("hidden");
                    $("#bed-text-container").addClass("hidden");
                }


                // console.log("active", activeMenuItemBName);
                // disableBedFeet(activeMenuItemBName);
                changeDimensionsBeforeCalcPrice({dimensions, isBedsOn});

                getActiveStateProps();
                if (BEDS_WITHOUT_BEDFEET.includes(activeMenuItemBName.toLowerCase())
                    || BASE_WITHOUT_BEDFEET.includes(activeMenuItemBName.toLowerCase())) {
                    $("#bed-foot").addClass("disabled");
                } else {
                    $("#bed-foot").removeClass("disabled");
                }

                await calcPrice({dimensions});
                if (!isBedsOn)
                    setMinMaxValueAccordingToHeadboard();
                changeDimensionsBeforeCalcPrice({dimensions});

            }


            $('.layout-selection').removeClass('active');
            this.classList.add('active');
            return changeImages();
        });


    } catch (err) {
        console.log(err);
        console.log('Function argument should be a JS object');
    }
}


//function to change the layoutState of the main image using the current layout
function closureForImageChange(currentLayout) {
    try {
        let imagesArray = [];
        for (let [adiaforo, divComponents] of Object.entries(currentLayout)) {
            let newObj = {};
            for (let [key, value] of Object.entries(divComponents)) {
                if (key === "imageDivId" ||
                    key === "imageUrl") {
                    newObj[key] = value;
                    imagesArray.push(newObj);
                }
            }
        }

        return replaceImages(imagesArray);
    } catch (e) {
        console.log(e);
        console.log("manage your argument - Should be a JS Object");
    }
}

export function constructSelectionsOnload() {
    let sessionSavedUserSelections = document.querySelector("#userSelections").textContent;
    if (
        JSON.parse(sessionSavedUserSelections) === "null" ||
        JSON.parse(sessionSavedUserSelections) === null ||
        JSON.parse(sessionSavedUserSelections).length === 0
    ) {
        voidSessionSelections();
    } else {
        //    You can save your current configuration using the save button
        // at the right and load it back from here at any time!
        let arrayOfObjects = JSON.parse(sessionSavedUserSelections);
        arrayOfObjects.forEach(option => constructNewSelection(option));
    }
}

function voidSessionSelections() {
    const userSelectionContainer = document.querySelector('#selections-container');
    const newPara = document.createElement('p');
    newPara.className = 'user-selection-text';
    newPara.innerHTML = `<p class="user-selection-text">  Μπορείτε να αποθηκεύσετε την τρέχουσα 
διαμόρφωση χρησιμοποιώντας το κουμπί αποθήκευσης στα δεξιά και να την επαναφορτώσετε από εδώ ανά πάσα στιγμή!</p>`;
    userSelectionContainer.appendChild(newPara);
}

function changeDimensionsBeforeCalcPrice({dimensions}) {
    const {bedLength, bedWidth, headboardWidth} = dimensions;
    updateSlider(BASE_LENGTH_RANGE_SELECTOR, +bedLength);
    updateSlider(BASE_WIDTH_RANGE_SELECTOR, +bedWidth);
    updateSlider(HEADBOARD_WIDTH_RANGE_SELECTOR, +headboardWidth);
}