/*
 * The layout_type can be either multi-layout or single-layout.
 * (Doppler is multi-layout and MediaStrom is single-layout)
 *
*/
import {activeParentCodes, orderId, sessionPrefix} from "./index";
import $ from "jquery";
import {BEDFEET_MENU_NAME, BEDS} from "./consts";
import {disableBedFeet} from "./UIfunctions";
import {getRequest} from "./requests";

export async function getJson(e, category = 'a', id = 1, buildFromSession = false, layoutType, layoutName, savedMenuState = '', activeMenuItemA = "") {
    /*
     * You can call getJson('all', 0, true) to get all the menu.
     * You can call getJson('c', 3) to get the menu from CategoryC with menuItemID=3 and below.
    */
    let menuItemASelected;
    if ($('#bed-foot').hasClass('active') && (e.target.dataset.propOverwritecategory === "bed-feet"
        || e.target.parentNode.dataset.propOverwritecategory === "bed-feet")) {
        menuItemASelected = document.querySelector('#category-a [data-name=' + BEDFEET_MENU_NAME + ']').dataset.id;
    } else if (e.target?.dataset?.propOverwritecategory === "background-subcategories"
        || e.target?.parentNode?.dataset?.propOverwritecategory === "background-subcategories"
        || e.target?.dataset?.propOverwritecategory === "background-floors"
        || e.target?.parentNode?.dataset?.propOverwritecategory === "background-floors") {
        // console.log(document.querySelector("#background-subcategories [data-category='a']").dataset.id);
        menuItemASelected = document.querySelector("#background-subcategories [data-category='a']").dataset.id;
    } else {
        menuItemASelected = activeMenuItemA ? activeMenuItemA : $('#category-a .menu-item.active').attr('data-id');

    }
    let activeBedfeetId = disableBedFeet() ?? "";
    // console.log("activeBedfeetId", activeBedfeetId);

    // console.log("buildFromSession", buildFromSession, "layoutType", layoutType, "layoutName", layoutName);
    let isBedsOn = activeParentCodes?.lastUsed() === BEDS;

    const url = window.location.href;
    const linenStoriz = url.includes("#linen_storiz");
    return await getRequest(
        '/get-my-json/',
        {
            orderId: orderId,
            sessionPrefix: sessionPrefix,
            menuItemASelected: menuItemASelected,
            category: category,
            id: id,
            buildFromSession: buildFromSession,
            layoutType: layoutType ? layoutType : '',
            layoutName: layoutName ? layoutName : '',
            activeBedfeetId,
            savedMenuState: savedMenuState ? JSON.stringify(savedMenuState) : '',
            isBedsOn,
            linenStoriz
        }
    );
}

