import $ from "jquery";

export const getRequest = (url, params, options) =>
    request(url, params, options, "GET");

export const postRequest = (url, params, options) =>
    request(url, params, options, "POST");

const request = (url, params = {}, options = {}, method = "GET") => {
    //     console.log('params', params);
    options.method = method;
    if ("GET" === method) {
        url += "?" + new URLSearchParams(params).toString();
    } else {
        options.body = JSON.stringify(params);
        options.credentials = "same-origin";
        options.headers = {
            "X-CSRFToken": $("input[name=csrfmiddlewaretoken]").val(),
        };
    }
    //     console.log('options', options);
    if (!url.includes("get-pdf")) {
        return fetch(url, options)
            .then((response) => response.json())
            .catch((err) => console.log("error", err));
    } else {
        return fetch(url, options)
            .then((response) => {
                const disposition = response.headers.get("Content-Disposition");
                const filename = disposition
                    ? /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
                          disposition
                      )[1]
                    : "";
                response.blob().then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = filename.replace(/['"]/g, ""); // Set download attribute to the filename from the server
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
            })
            .catch((err) => console.log("error", err));
    }
};
