import {magnify} from "./UIfunctions";

export function showHideDivs(show, divsArray, updateLayoutState = true) {
    for (let i = 0; i < divsArray.length; i++) {
        let div = divsArray[i];
        let divElement = document.querySelector(`[data-div-name="${div}"]`);
        if (divElement) {
            divElement.style.display = show ? "" : "none";
        }

        // Update the layoutState
        if (updateLayoutState) {
            global.layoutState[div]['imageVisible'] = show;
        }
    }
}

