import $ from 'jquery';

let backdrop = document.createElement('div');
backdrop.classList.add('loader-backdrop');
backdrop.classList.add('image-display');

let loaderContainer = document.createElement('div');
loaderContainer.classList.add('container');

let loaderRow = document.createElement('div');
loaderRow.classList.add('row');

let loaderRowCol = document.createElement('div');
loaderRowCol.classList.add('col-sm-6');
loaderRowCol.classList.add('text-center');

let loader = document.createElement('div');
loader.classList.add('loader');

// export const useLoader = backdrop.appendChild(loaderContainer);


export const useLoader = () => {

    $('#images-container').append(backdrop);
    $('.loader-backdrop').append(loaderContainer);
    $('.loader-backdrop .container').append(loaderRow);
    $('.loader-backdrop .container .row').append(loaderRowCol);
    $('.loader-backdrop .container .row .col-sm-6').append(loader);


};

export function hideLoader() {
    $('#images-container .loader-backdrop').remove();
}


