import $ from "jquery";
import {BASES_MENU_NAME, HEADBOARD_MENU_NAME} from "./consts";
import ionRangeSlider from 'ion-rangeslider';
import {BASE_LENGTH_RANGE_SELECTOR, BASE_WIDTH_RANGE_SELECTOR, HEADBOARD_WIDTH_RANGE_SELECTOR} from "./UIfunctions";

const [connectRange] = $('#connect-range');

function updateSlider(s, obj) {
    $(s).data("ionRangeSlider").update(obj);
}

/* *************** sets the min and max values of Bases and HeadBoards (should be called every time a category-b item changes) *********** */
function setMinMaxValueAccordingToBase(element) {
    let activeBaseAttrs = global.menuStateNames[$('#category-a [data-name=' + BASES_MENU_NAME + ']').attr('data-id')]['b'],
        name = activeBaseAttrs['name'],
        propMaxWidth = activeBaseAttrs['max-width'],
        propMinWidth = activeBaseAttrs['min-width'],
        propMaxLength = activeBaseAttrs['max-length'],
        propMinLength = activeBaseAttrs['min-length'],
        propWidthStep = activeBaseAttrs['width-step'],
        propLengthStep = activeBaseAttrs['length-step'],
        baseWidthInputRangeData = $(BASE_WIDTH_RANGE_SELECTOR).data("ionRangeSlider").result,
        baseLengthInputRangeData = $(BASE_LENGTH_RANGE_SELECTOR).data("ionRangeSlider").result,
        headboardWidthInputRangeData = $(HEADBOARD_WIDTH_RANGE_SELECTOR).data("ionRangeSlider").result;

    if (element) {
        name = element.dataset.name;
        propMaxWidth = element.dataset.propMaxWidth;
        propMinWidth = element.dataset.propMinWidth;
        propMaxLength = element.dataset.propMaxLength;
        propMinLength = element.dataset.propMinLength;
        propWidthStep = element.dataset.propWidthStep;
        propLengthStep = element.dataset.propLengthStep;
    }


    if (propMaxWidth === propMinWidth) {
        $('#dimension-input-1-headboard, #headboard-width-range-container').addClass('disabled');
    } else {
        $('#dimension-input-1-headboard, #headboard-width-range-container').removeClass('disabled');
    }

    if (propMaxLength === propMinLength) {
        $('#dimension-input-2, #base-length-range-container').addClass('disabled');
    } else {
        $('#dimension-input-2, #base-length-range-container').removeClass('disabled');
    }

    // Set the base max width to range and input max attribute.
    baseWidthInputRangeData.max = propMaxWidth;

    // Set the base min width to range and input min attribute.
    baseWidthInputRangeData.min = propMinWidth;

    // Set base max length to range and input min attribute.
    baseLengthInputRangeData.max = propMaxLength;

    // Set the base min length to range and input min attribute.
    baseLengthInputRangeData.min = propMinLength;

    // Set the four base input step.
    baseLengthInputRangeData.step = propLengthStep;

    baseWidthInputRangeData.step = propWidthStep;

    let headboards = document.querySelector('#category-a [data-name=' + HEADBOARD_MENU_NAME + ']').dataset.id,
        propsObject;

    if (global.menuStateNames) {
        let propNameVar = global.menuStateNames[headboards];
        for (let value in propNameVar) {
            if (value === 'b') {
                propsObject = propNameVar[value];
            }
        }
    } else {
        propsObject = undefined;
    }

    if (baseWidthInputRangeData.from === '') {
        baseWidthInputRangeData.min = propMinWidth;
    }

    if (baseLengthInputRangeData.from === '') {
        baseLengthInputRangeData.min = propMinLength;
    }

    updateSlider(BASE_WIDTH_RANGE_SELECTOR, {
        from: baseWidthInputRangeData.from,
        max: baseWidthInputRangeData.max,
        min: baseWidthInputRangeData.min,
        step: baseWidthInputRangeData.step,

    });
    updateSlider(BASE_LENGTH_RANGE_SELECTOR, {
        from: baseLengthInputRangeData.from,
        max: baseLengthInputRangeData.max,
        min: baseLengthInputRangeData.min,
        step: baseLengthInputRangeData.step,

    });

    if (propsObject?.name !== 'Amalfi' && propsObject?.name !== undefined) {
        setRestrictionsOnMenuStateChange();
    }
}

function setMinMaxValueAccordingToHeadboard(active) {
    let propsObject,
        activeHeadboard,
        catAHeadboardMenu = $('#category-a [data-name=' + HEADBOARD_MENU_NAME + ']'),
        baseWidthInputRangeData = $(BASE_WIDTH_RANGE_SELECTOR).data("ionRangeSlider").result,
        baseLengthInputRangeData = $(BASE_LENGTH_RANGE_SELECTOR).data("ionRangeSlider").result,
        headboardWidthInputRangeData = $(HEADBOARD_WIDTH_RANGE_SELECTOR).data("ionRangeSlider").result;

    if (catAHeadboardMenu.hasClass('active')) {
        [activeHeadboard] = $('#category-b .active').length === 0 ? $('#tivoli-subcategories .active') : $('#category-b .active');
    } else {
        let headboardId = catAHeadboardMenu[0].dataset.id;
        activeHeadboard = global.menuStateNames[headboardId].b;
    }

    if (active !== undefined) {
        activeHeadboard = active[0];
    }

    //get base max width, get base max width , get base max length,  get base max length,  get the step for this base width,get the ste for this base length
    let propName = catAHeadboardMenu.hasClass('active') ? activeHeadboard.dataset.propName : activeHeadboard.name,
        propMaxWidth = catAHeadboardMenu.hasClass('active') ? activeHeadboard.dataset.propMaxWidth : activeHeadboard['max-width'],
        propMinWidth = catAHeadboardMenu.hasClass('active') ? activeHeadboard.dataset.propMinWidth : activeHeadboard['min-width'],
        propWidthStep = catAHeadboardMenu.hasClass('active') ? activeHeadboard.dataset.propWidthStep : activeHeadboard['width-step'];

    // set the headboard max width to range and input max attribute
    headboardWidthInputRangeData.max = propMaxWidth;

    // set the base min width to range and input min attribute
    headboardWidthInputRangeData.min = propMinWidth;

    // set the for base input step
    headboardWidthInputRangeData.step = propWidthStep;

    if ($('#category-a  [data-name=' + HEADBOARD_MENU_NAME + ']').hasClass('active')) {
        let bases = document.querySelector('#category-a [data-name=' + BASES_MENU_NAME + ']').dataset.id;
        if (global.menuStateNames) {
            let propNameVar = global.menuStateNames[bases];
            for (let value in propNameVar)
                if (value === 'b')
                    propsObject = propNameVar[value];
        }
    }

    if (headboardWidthInputRangeData.from === '') {
        headboardWidthInputRangeData.from = propMinWidth;
    }

    if (propName === "Amalfi") {
        if (Number(headboardWidthInputRangeData.from) < Number(propMinWidth)) {
            headboardWidthInputRangeData.from = propMinWidth;
        } else if (Number(headboardWidthInputRangeData.from) > Number(propMaxWidth)) {
            headboardWidthInputRangeData.from = propMinWidth;
        }
    }

    updateSlider(HEADBOARD_WIDTH_RANGE_SELECTOR, {
        from: headboardWidthInputRangeData.from,
        max: headboardWidthInputRangeData.max,
        min: headboardWidthInputRangeData.min,
        step: headboardWidthInputRangeData.step
    });

    if (propName !== 'Amalfi') {
        setRestrictionsOnMenuStateChange();
    }
}

/* *************** sets restrictions of Bases and HeadBoards values (should be called after values are set) *********** */
function restrictionsAccordingToBaseWidth(baseWidth) {
    // find active base
    let [activeBase] = $('#category-b .active'),
        headboards = document.querySelector('#category-a [data-name=' + HEADBOARD_MENU_NAME + ']').dataset.id,
        propname,
        baseWidthInputRangeData = $(BASE_WIDTH_RANGE_SELECTOR).data("ionRangeSlider").result,
        baseLengthInputRangeData = $(BASE_LENGTH_RANGE_SELECTOR).data("ionRangeSlider").result,
        headboardWidthInputRangeData = $(HEADBOARD_WIDTH_RANGE_SELECTOR).data("ionRangeSlider").result;

    if (global.menuStateNames) {
        let propNameVar = global.menuStateNames[headboards];
        for (let value in propNameVar)
            if (propNameVar[value])
                propname = propNameVar[value];
    } else {
        propname = '';
    }

    // get active base props
    let {
        propName,
        propMinWidth,
        propMaxWidth,
        propWidthStep,
    } = activeBase.dataset;

    if (propname !== 'Amalfi')
        if (connectRange.value === '2') {
            if (headboardWidthInputRangeData.max !== '') {
                //get the Bases Max value
                if (propMaxWidth < headboardWidthInputRangeData.max) {
                    baseWidthInputRangeData.max = propMaxWidth;
                }
                if (propMinWidth > headboardWidthInputRangeData.min) {
                    baseWidthInputRangeData.min = propMinWidth;
                } else if (propMinWidth < headboardWidthInputRangeData.min) {
                    baseWidthInputRangeData.min = headboardWidthInputRangeData.min;
                    propMinWidth = baseWidthInputRangeData.min;
                }
                propWidthStep = 10;
            }
        }

    let headBoardprops = global.menuStateNames[headboards].b;

    if (propName === 'Flexy High' || propName === 'Flexy' || propName === 'Precious' || propName === 'Minimal' || propName === 'Basic') {
        if (baseWidth < Number(propMinWidth)) {
            baseWidth = propMinWidth;
        } else if (baseWidth > Number(propMaxWidth)) {
            baseWidth = propMaxWidth;
        } else if (baseWidth % Number(propWidthStep) !== 0) {
            let baseWidthTmp = baseWidth;
            while (baseWidthTmp % Number(propWidthStep) !== 0) {
                baseWidthTmp++;
            }
            baseWidth = baseWidthTmp;
        }
    } else if (propName === 'Storage') {
        if (baseWidth < Number(propMinWidth)) {
            baseWidth = propMinWidth;
        } else if (baseWidth > Number(propMaxWidth)) {
            baseWidth = propMaxWidth;
        } else if (baseWidth % Number(propWidthStep) !== 0
            ||
            (baseWidth % Number(propWidthStep) === 0
                && (Number(baseWidth) >= 130 && Number(baseWidth) < 140))
        ) {
            let baseWidthTmp = baseWidth;
            while (baseWidthTmp % Number(propWidthStep) !== 0 ||
                (baseWidth % Number(propWidthStep) === 0
                    && (Number(baseWidthTmp) >= 130 && Number(baseWidthTmp) < 140))
                ) {
                baseWidthTmp++;
            }
            baseWidth = baseWidthTmp;
        }
    }
    if (connectRange.value === '2') {
        if (headBoardprops.name !== 'Tivoli' && headBoardprops.name !== 'Amalfi') {
            if (baseWidth > Number(propMaxWidth)) {
                baseWidth = propMaxWidth;
            } else if (baseWidth < Number(propMinWidth)) {
                baseWidth = propMinWidth;
            } else if (baseWidth % Number(propWidthStep)) {
                let headboardWidthTmp = baseWidth;
                while (headboardWidthTmp % Number(propWidthStep)) {
                    headboardWidthTmp++;
                }
                baseWidth = headboardWidthTmp;
            }
        } else if (headBoardprops.name === 'Amalfi') {
            return;
        } else {
            if (baseWidth > Number(propMaxWidth)) {
                baseWidth = propMaxWidth;
            } else if (baseWidth < Number(propMinWidth)) {
                baseWidth = propMinWidth;
            } else if (Number(baseWidth) > 110 && Number(baseWidth) < 150) {
                baseWidth = '150';
            } else if (Number(baseWidth) > 160 && Number(baseWidth) < 180) {
                baseWidth = '180';
            } else if (baseWidth % Number(propWidthStep)) {
                let headboardWidthTmp = baseWidth;
                while (headboardWidthTmp % Number(propWidthStep))
                    headboardWidthTmp++;
                baseWidth = headboardWidthTmp;
            }
        }
    }

    baseWidthInputRangeData.from = baseWidth;
    baseWidthInputRangeData = {
        from: baseWidthInputRangeData.from,
        max: baseWidthInputRangeData.max,
        min: baseWidthInputRangeData.min,
        step: baseWidthInputRangeData.step,
        // onFinish: function () {
        //     if (propname === 'Tivoli') {
        //         document.querySelector('#headboards-tooltip').textContent = "baseWidthInputRangeData.from";
        //     }
        // }
    };

    updateSlider(BASE_WIDTH_RANGE_SELECTOR, baseWidthInputRangeData);

    // If the selected Headboard is NOT `Amalfi` then change the Headboard width to be the same as the
    // just-changed Base width.
    try {
        if (connectRange.value === '2' && propname !== 'Amalfi') {
            updateSlider(HEADBOARD_WIDTH_RANGE_SELECTOR, baseWidthInputRangeData);
        }
    } catch (e) {
    }
}

function restrictionsAccordingToBaseLength(baseLength) {
    // find active base
    let [activeBase] = $('#category-b .active');

    // get active base props
    let {
        propMinLength,
        propMaxLength,
        propLengthStep
    } = activeBase.dataset;

    if (baseLength < Number(propMinLength)) {
        baseLength = propMinLength;
    } else if (baseLength > Number(propMaxLength)) {
        baseLength = propMaxLength;
    } else if (baseLength % Number(propLengthStep) !== 0 && propLengthStep !== '0') {
        let baseLengthTmp = baseLength;
        while (baseLengthTmp % Number(propLengthStep) !== 0) {
            baseLengthTmp++;
        }
        baseLength = baseLengthTmp;
    }

    updateSlider(BASE_LENGTH_RANGE_SELECTOR, {
        from: baseLength,
        max: propMaxLength,
        min: propMinLength,
        step: propLengthStep
    });
}

function restrictionsAccordingToHeadBoardWidth(headBoardWidth, active) {
    let [activeHeadboard] = $('#category-b .active').length === 0 ? $('#tivoli-subcategories .active') : $('#category-b .active'),
        baseWidthInputRangeData = $(BASE_WIDTH_RANGE_SELECTOR).data("ionRangeSlider").result,
        baseLengthInputRangeData = $(BASE_LENGTH_RANGE_SELECTOR).data("ionRangeSlider").result,
        headboardWidthInputRangeData = $(HEADBOARD_WIDTH_RANGE_SELECTOR).data("ionRangeSlider").result;

    // Check if checkbox is evaluated true, if it is true Headboard dimensions should get the max and min value
    // according the biggest min value and the smallest maxvalue.
    if (active !== undefined) {
        [activeHeadboard] = active;
    }
    // console.log("headboardWidthInputRangeData.from",headboardWidthInputRangeData.from);
    const initialClickOnInput = headboardWidthInputRangeData.from;
    let {
        propName,
        propMaxWidth,
        propMinWidth,
        propWidthStep
    } = activeHeadboard.dataset;

    if (propName !== 'Amalfi') {
        if (connectRange.value === '2') {
            //get the Bases Max value
            let baseMaxValue = baseWidthInputRangeData.max,
                baseMinValue = baseWidthInputRangeData.min;
            if (propMaxWidth < baseMaxValue) {
                propMaxWidth = baseMaxValue;
            }
            if (propMinWidth < baseMinValue) {
                propMinWidth = baseMinValue;
            }
        }
    }

    if (propName !== 'Tivoli' && propName !== 'Amalfi') {
        if (headBoardWidth > Number(propMaxWidth))
            headBoardWidth = propMaxWidth;
        else if (headBoardWidth < Number(propMinWidth))
            headBoardWidth = propMinWidth;
        else if (headBoardWidth % Number(propWidthStep)) {
            let headboardwidth = headBoardWidth;
            while (headboardwidth % Number(propWidthStep)) {
                headboardwidth++;
            }
            headBoardWidth = headboardwidth;
        }
    } else if (propName === 'Amalfi') {
        if (headBoardWidth > Number(propMaxWidth))
            headBoardWidth = propMaxWidth;
        else if (headBoardWidth < Number(propMinWidth))
            headBoardWidth = propMinWidth;
    } else {
        if (headBoardWidth > Number(propMaxWidth))
            headBoardWidth = propMaxWidth;
        else if (headBoardWidth < Number(propMinWidth))
            headBoardWidth = propMinWidth;
        else if (Number(headBoardWidth) > 110 && Number(headBoardWidth) < 150) {
            headBoardWidth = '150';
        } else if (Number(headBoardWidth) > 160 && Number(headBoardWidth) < 180) {
            headBoardWidth = '180';
        } else if (headBoardWidth % Number(propWidthStep)) {
            let tivoliwidth = headBoardWidth;
            while (tivoliwidth % Number(propWidthStep))
                tivoliwidth++;
            headBoardWidth = tivoliwidth;
        }
    }

    let headBoardWidthInputRangeObj = {
        from: headBoardWidth,
        max: propMaxWidth,
        min: propMinWidth,
        step: propWidthStep,
        onUpdate: function () {
            let context = `Το κεφαλάρι ${propName} δεν μπορεί να πάρει την τιμή:  <span id="escaped-value">${initialClickOnInput}εκ<span>`;
            if (propName === 'Tivoli' && (initialClickOnInput > 110 && initialClickOnInput < 150)) {
                if(document.querySelector('#headboards-tooltip')) {
                    document.querySelector('#headboards-tooltip')?.classList.remove('hidden');
                    document.querySelector('#headboards-tooltip').innerHTML = context;
                }
            } else {
                document.querySelector('#headboards-tooltip')?.classList.add('hidden');
            }
        }
    };
    updateSlider(HEADBOARD_WIDTH_RANGE_SELECTOR, headBoardWidthInputRangeObj);

    // If the selected Headboard is NOT `Amalfi` then change the Base width to be the same as the just-changed
    // Head Board width.
    try {
        if (connectRange.value === '2' && propName !== 'Amalfi') {
            updateSlider(BASE_WIDTH_RANGE_SELECTOR, headBoardWidthInputRangeObj);
        }
    } catch (e) {
    }
}

function getActiveStateProps() {
    if ($('#category-d .menu-item').hasClass('active')) {
        let [activeState] = $('#category-d .active'),
            {propName, propCategory, propSubcategory, propColor} = activeState.dataset,
            propsArray = [propName, propCategory, propSubcategory, propColor].filter(el => el !== undefined),
            product = propsArray.reduce((acc, el, index) => {
                if (index === 0)
                    return '';

                return `${acc}/ ${el}`;
            }, '');

        let activeColorName = document.querySelector('#category-c .active .menu-item-name')?.textContent;
        product = product.toLowerCase();
        document.querySelector('#breadcrumbs').innerHTML = '';
        document.querySelector('#breadcrumbs').textContent = `${activeColorName} ${product}`;
    }
}

function setRestrictionsOnMenuStateChange() {
    let [active] = $('#category-b .active').length === 0 ? $('#tivoli-subcategories .active') : $('#category-b .active'),
        {
            propName,
            propMinWidth,
            propMaxWidth,
            propWidthStep,
        } = active.dataset,
        baseWidthInputRangeData = $(BASE_WIDTH_RANGE_SELECTOR).data("ionRangeSlider").result,
        baseLengthInputRangeData = $(BASE_LENGTH_RANGE_SELECTOR).data("ionRangeSlider").result,
        headboardWidthInputRangeData = $(HEADBOARD_WIDTH_RANGE_SELECTOR).data("ionRangeSlider").result;



    if ($('#category-a [data-name=' + BASES_MENU_NAME + ']').hasClass('active')) {
        const {from, min, max, step} = headboardWidthInputRangeData;

        let headboards = document.querySelector('#category-a [data-name=' + HEADBOARD_MENU_NAME + ']').dataset.id,
            propsObject;
        if (global.menuStateNames) {
            let propNameVar = global.menuStateNames[headboards];
            for (let value in propNameVar)
                if (value === 'b')
                    propsObject = propNameVar[value];
        } else {
            propsObject = {};
        }

        let maxWidth = propsObject['max-width'],
            minWidth = propsObject['min-width'];

        if (propsObject.name !== 'Amalfi' && connectRange.value === '2') {
            propWidthStep = '10';
            baseWidthInputRangeData.step = propWidthStep;
            headboardWidthInputRangeData.max = maxWidth;
            headboardWidthInputRangeData.min = minWidth;

            if (Number(propMaxWidth) > Number(headboardWidthInputRangeData.max) && headboardWidthInputRangeData.max !== '') {
                baseWidthInputRangeData.max = headboardWidthInputRangeData.max;
            } else if (Number(propMaxWidth) < Number(headboardWidthInputRangeData.max) && headboardWidthInputRangeData.max !== '') {
                headboardWidthInputRangeData.max = baseWidthInputRangeData.max;
            }

            if (Number(propMinWidth) > Number(headboardWidthInputRangeData.min) && headboardWidthInputRangeData.min !== '') {
                headboardWidthInputRangeData.min = propMinWidth;
            } else if (Number(propMinWidth) < Number(headboardWidthInputRangeData.min) && headboardWidthInputRangeData.min !== '') {
                baseWidthInputRangeData.min = headboardWidthInputRangeData.min;
            }
        }

        if (Number(baseWidthInputRangeData.from) < Number(baseWidthInputRangeData.min)) {
            baseWidthInputRangeData.from = baseWidthInputRangeData.min;
        } else if (Number(baseWidthInputRangeData.from) > Number(baseWidthInputRangeData.max)) {
            baseWidthInputRangeData.from = baseWidthInputRangeData.max;
        }

        if (Number(headboardWidthInputRangeData.from) !== Number(baseWidthInputRangeData.from) && connectRange.value === '2' && propsObject?.name !== 'Amalfi') {
            headboardWidthInputRangeData.from = baseWidthInputRangeData.from;
        }

        updateSlider(BASE_WIDTH_RANGE_SELECTOR, {
            from: baseWidthInputRangeData.from,
            max: baseWidthInputRangeData.max,
            min: baseWidthInputRangeData.min,
            step: baseWidthInputRangeData.step
        });

        updateSlider(BASE_LENGTH_RANGE_SELECTOR, {
            from: baseLengthInputRangeData.from,
            max: baseLengthInputRangeData.max,
            min: baseLengthInputRangeData.min,
            step: baseLengthInputRangeData.step
        });

        updateSlider(HEADBOARD_WIDTH_RANGE_SELECTOR, {
            from: headboardWidthInputRangeData.from,
            max: headboardWidthInputRangeData.max,
            min: headboardWidthInputRangeData.min,
            step: headboardWidthInputRangeData.step
        });
    } else if ($('#category-a [data-name=' + HEADBOARD_MENU_NAME + ']').hasClass('active')) {
        const {from, min, max, step} = headboardWidthInputRangeData;

        let bases = document.querySelector('#category-a [data-name=' + BASES_MENU_NAME + ']').dataset.id,
            propsObject;

        if (global.menuStateNames) {
            let propNameVar = global.menuStateNames[bases];
            for (let value in propNameVar)
                if (value === "b")
                    propsObject = propNameVar[value];
        } else {
            propsObject = {};
        }

        let maxWidth = propsObject['max-width'],
            minWidth = propsObject['min-width'],
            minLength = propsObject['min-length'],
            maxLength = propsObject['max-length'];

        if (propName !== 'Amalfi' && connectRange.value === '2') {
            baseWidthInputRangeData.max = maxWidth;
            baseWidthInputRangeData.min = minWidth;
            baseLengthInputRangeData.max = maxLength;
            baseLengthInputRangeData.min = minLength;

            if (Number(propMaxWidth) > Number(maxWidth)) {
                headboardWidthInputRangeData.max = baseWidthInputRangeData.max;
            } else if (Number(propMaxWidth) < Number(maxWidth)) {
                baseWidthInputRangeData.max = propMaxWidth;
            }
            if (Number(propMinWidth) > Number(minWidth)) {
                baseWidthInputRangeData.min = propMinWidth;
            } else if (Number(propMinWidth) < Number(minWidth)) {
            }
        }

        if (Number(headboardWidthInputRangeData.from) > Number(headboardWidthInputRangeData.max)) {
            headboardWidthInputRangeData.from = headboardWidthInputRangeData.max;
        } else if (Number(headboardWidthInputRangeData.from) < Number(headboardWidthInputRangeData.min)) {
            headboardWidthInputRangeData.from = headboardWidthInputRangeData.min;
        }

        if (Number(headboardWidthInputRangeData.from) !== Number(baseLengthInputRangeData.from) && connectRange.value === '2' && propName !== 'Amalfi') {
            baseWidthInputRangeData.from = headboardWidthInputRangeData.from;
        }

        updateSlider(BASE_WIDTH_RANGE_SELECTOR, {
            from: baseWidthInputRangeData.from,
            max: baseWidthInputRangeData.max,
            min: baseWidthInputRangeData.min,
            step: baseWidthInputRangeData.step
        });

        updateSlider(BASE_LENGTH_RANGE_SELECTOR, ({
            from: baseLengthInputRangeData.from,
            max: baseLengthInputRangeData.max,
            min: baseLengthInputRangeData.min,
            step: baseLengthInputRangeData.step
        }));

        updateSlider(HEADBOARD_WIDTH_RANGE_SELECTOR, {
            from: headboardWidthInputRangeData.from,
            max: headboardWidthInputRangeData.max,
            min: headboardWidthInputRangeData.min,
            step: headboardWidthInputRangeData.step
        });
    }
}

export {
    updateSlider,
    setMinMaxValueAccordingToHeadboard,
    setMinMaxValueAccordingToBase,
    restrictionsAccordingToHeadBoardWidth,
    getActiveStateProps,
    restrictionsAccordingToBaseWidth,
    restrictionsAccordingToBaseLength
};