export const WOO_SHOP_URL = 'https://www.mediastrom.com';
// export const WOO_SHOP_URL = 'https://serene-shirley.65-108-96-178.plesk.page';
// export const WOO_SHOP_URL = 'https://mediastrom.wslab.eu';
export const WOO_SHOP_ADD_TO_CART_PHP_URL = WOO_SHOP_URL + '/fsv-external-add-to-cart';
export const IMAGES_VERSION_CACHE_REFRESH = 1;
export const HEADBOARD_MENU_NAME = "Κεφαλάρι";
export const BASES_MENU_NAME = "Βάση-Υπόστρωμα";
export const BEDFEET_MENU_NAME = "Ποδαράκι";
export const BEDS = "Κρεβάτι";

export const LEYKA = "Λευκά Είδη";
export const bedAttributes = {
        Fit: "Βάση με ενιαία, μασίφ ξύλινη επιφάνεια με ύψος προφίλ: 20cm(+/- 1,0cm)",
        Flexy: "Βάση με εσωτερικό από αφρώδες υλικό ειδικής πυκνότητας, χωρίς ελατήρια με ύψος προφίλ: 17cm(+/- 1,0cm)",
        FlexyHigh: "Βάση με εσωτερικό από αφρώδες υλικό ειδικής πυκνότητας, χωρίς ελατήρια με ύψος προφίλ: 22cm(+/- 1,0cm)",
        Minimal: "Βάση με ενιαία, ξύλινη επιφάνεια με ύψος προφίλ: 8cm(+/- 1,0cm)",
        Basic: "Βάση με συνδεδεμένα ελατήρια Bonnel, με ύψος προφίλ: 21cm(+/- 1,0cm)",
        Precious: "Βάση με σύστημα ανεξάρτητων ελατηρίων 2 επιπέδων και 3 ζωνών,  , με ύψος προφίλ: 28cm(+/- 1,0cm)",
        Storage: "Βάση με ενιαίο αποθηκευτικό χώρο, με ύψος προφίλ: 26cm(+/- 1,0cm)",
    },
    BASE_WITHOUT_BEDFEET = ["fit"],
    BEDS_WITHOUT_BEDFEET = ["sorento mediflex", "capri", "venice"];

export const
    SHOW_FABRIC_FILTERS = "showFabricFiltersInFrontend",
    BED_GENERAL_PRICE_CHECKBOX = "bed_general_price_checkbox",
    LEFKA_EIDH_GENERAL_PRICE_CHECKBOX = "leyka_eidh_general_price_checkbox",
    DIAKOSMITIKA_MAKSILARIA_CONST_NAME = "diakosmitika_maksilaria",
    KATOSENTONO_CONST_NAME = "katosentono",
    KOUVERLI_CONST_NAME = "kouverli",
    MAKSILARIA_CONST_NAME = "maksilaria",
    PANOSENTONO_CONST_NAME = "panosentono",
    PAPLOMATOTHIKI_CONST_NAME = "paplomatothiki",
    SET_SENTONIA_CONST_NAME = "set_sentonia";
