import {BASES_MENU_NAME, BEDS, HEADBOARD_MENU_NAME} from "./consts.js";
import $ from "jquery";

export function activeParentClosure({codes}) {
    const code = codes;
    let activeBed = null;
    let propMaxWidth = 170;
    let propMaxLength = 220;
    let last = BASES_MENU_NAME;

    function setBedsOnly(value) {
        if (value === true) {
            let activeBItem = $("#category-b .active")[0],
                    bedText = $("#bed-text");
                bedText.text(activeBItem.dataset.propBedText);
                document.querySelector(('#bed-text-container')).classList.remove('hidden');
            document.querySelector('.dimensions.container.headboards').classList.add('hidden');
            last = BEDS;
        }
    }
    // function to return the active parent
    function activeParent(element) {
        const {name} = element.dataset;
        if (name === HEADBOARD_MENU_NAME || name === BASES_MENU_NAME) {
            return code.baseCode;
        } else if (name === BEDS) {
            return code.bedCode;
        }
    }

    function setActiveBed({bedElement}) {
        activeBed = bedElement;
    }

    function getMaxBedDimensions() {
          try {
              if (activeBed?.dataset){
                  let {propMaxWidth , propMaxLength} = activeBed.dataset;
                  return {propMaxWidth, propMaxLength};
              }
              else
                  if (propMaxWidth && propMaxLength) {
                    return {propMaxWidth, propMaxLength};
                  } else {
                    return {propMaxWidth: 170, propMaxLength: 220};
                  }
        }catch (e){
            console.log(e);
          }
    }

    function setMaxBedDimensions(maxWidth, maxLength) {
        propMaxWidth = maxWidth;
        propMaxLength = maxLength;
    }

    function lastUsed() {
        return last;
    }

    // function to set the last used
    function setLastUsed(element) {
        const {name} = element.dataset;
        if (name === HEADBOARD_MENU_NAME || name === BASES_MENU_NAME) {
            last = BASES_MENU_NAME;
        } else if (name === BEDS) {
            last = BEDS;
        }
    }

    // function to set the code
    function setCode(element) {
        const {propCode} = element.dataset;
        const activeADataName = document.querySelector('#category-a .menu-item.active').dataset.name;
        if (activeADataName === BASES_MENU_NAME) {
            code.baseCode = propCode;
        } else if (activeADataName === BEDS) {
            code.bedCode = propCode;
        }
    }

    return {activeParent, setCode, lastUsed,setLastUsed, setActiveBed, getMaxBedDimensions,setMaxBedDimensions, setBedsOnly};
}