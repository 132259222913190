import {BASE_LENGTH_RANGE_SELECTOR, BASE_WIDTH_RANGE_SELECTOR, HEADBOARD_WIDTH_RANGE_SELECTOR} from "./UIfunctions";
import {updateSlider} from "./dimensionsFunctions";

export default function setDimensionsForOrder({dimensions}) {
    if (!dimensions) return;
    let {bedLength, bedWidth, headboardWidth} = dimensions;
    updateSlider(BASE_WIDTH_RANGE_SELECTOR, {
        from: bedWidth
    });
    updateSlider(BASE_LENGTH_RANGE_SELECTOR, {
        from: bedLength
    });
    updateSlider(HEADBOARD_WIDTH_RANGE_SELECTOR, {from: headboardWidth});
}