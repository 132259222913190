import $ from "jquery";
import {toggleSelections} from "./selections";

export function showBackgrounds() {
    const backgroundContainer = document.querySelector("#backgrounds-container");
    const selectionsContainer = $("#selections-container");

    if (!selectionsContainer.hasClass('hidden')) {
        toggleSelections();
    }

    if (backgroundContainer.classList.contains("hidden")) {
        backgroundContainer.classList.toggle("hidden");
    } else {
        setTimeout(function () {
            backgroundContainer.classList.toggle("hidden");
        }, 800);
    }
    backgroundContainer.classList.toggle("animate__fadeIn");
    backgroundContainer.classList.toggle("animate__fadeOut");
    $('#backgrounds-on-off img').toggleClass('red-filter');
    // $("#backgrounds-container").toggleClass("animate__fadeIn").toggleClass("animate__fadeOut");
}

export default function () {
    document.querySelector("#backgrounds-on-off").addEventListener("click", showBackgrounds);
}

