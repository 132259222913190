import {IMAGES_VERSION_CACHE_REFRESH} from "./consts";
import {magnify} from "./UIfunctions";


export default function replaceImages(images) {
    for (let i = 0; i < images.length; i++) {
        let image = images[i];
        let imageElement = document.getElementById(`div-${image.imageDivId}`);

        if (imageElement) {
            // Show and update the image element
            let imgSrc = image.imageUrl ? `${image.imageUrl}?${IMAGES_VERSION_CACHE_REFRESH}` : "";
            imageElement.style.backgroundImage = `url("${imgSrc}")`;
            imageElement.style.display = image.imageVisible ? "" : "none";
        }
    }
}
